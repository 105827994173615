@import url('//fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('//fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

body {
    margin: 0;
    /* font-family: 'Calibri', 'Roboto', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    /* max-width: 100vw; */
    overflow-x: hidden;

}

body.isDesktop.lightbox-open {
    overflow: hidden;
    padding-right: 17px !important;
}

body.isDesktop.modal-open .ribbon-menu,
body.isDesktop.lightbox-open .ribbon-menu {
    padding-right: 25px !important;
}


body.isDesktop.lightbox-open nav,
body.isDesktop.modal-open nav {
    padding-right: 25px !important;
}


body.isDesktop.lightbox-open .app-menu,
body.isDesktop.lightbox-open .connect-btn,
body.isDesktop.lightbox-open .connect-window,
body.isDesktop.modal-open .app-menu,
body.isDesktop.modal-open .connect-btn,
body.isDesktop.modal-open .connect-window {
    right: 32px !important;
}

body.isDesktop.lightbox-open .cart-window,
body.isDesktop.modal-open .cart-window {
    right: 17px !important;
}

/* body.isDesktop.modal-open .dark-toggle-fixed {
    right: 17px !important;
} */

code {
    color: rgba(255, 150, 0);
    font-size: 1em !important;
}

.firebase-emulator-warning {
    font-size: .55rem;
    right: auto !important;
    width: 50px !important;
    overflow: hidden;
    padding: 1px;
}

input:focus {
    box-shadow: none !important;

}

.form-select,
.form-select:focus {
    box-shadow: none !important;
    cursor: pointer;
}

.scroll-div-vertical::-webkit-scrollbar {
    width: 5px;

}

.scroll-div-vertical::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

.scroll-div-vertical::-webkit-scrollbar-thumb {
    background-color: rgba(95, 95, 95, .4);
    border-radius: 10px;
    padding-left: 3px;
}

.scroll-div-vertical:hover::-webkit-scrollbar-thumb,
.scroll-div-vertical:focus::-webkit-scrollbar-thumb {
    background-color: rgba(95, 95, 95, .9);
}

.scroll-div-horizontal::-webkit-scrollbar {
    height: 8px;

}

.scroll-div-horizontal::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

.scroll-div-horizontal::-webkit-scrollbar-thumb {
    background-color: rgba(95, 95, 95, .4);
    border-radius: 20px;
    /* padding-left: 3px; */
}

.scroll-div-horizontal:hover::-webkit-scrollbar-thumb,
.scroll-div-horizontal:focus-within::-webkit-scrollbar-thumb {
    background-color: rgba(95, 95, 95, .9);
}

ion-icon {
    pointer-events: none;
}

a:link:not(.btn),
a:visited:not(.btn) {
    color: rgba(255, 150, 0, 1);
    /* color: rgba(255, 237, 38, 1); */
    text-decoration: none;
    font-weight: 400;
}

.bg-light a:hover:not(.btn) {
    color: rgba(0, 0, 0, 1) !important;
    text-decoration: none;
}

.bg-md-dark a:hover:not(.btn),
.bg-dark a:hover:not(.btn) {
    color: rgba(255, 255, 255, 1) !important;
    text-decoration: none;
}

/* a:hover:not(.btn) {
    color: rgba(255, 255, 255, 1);
    text-decoration: underline;
} */

a.disabled {
    pointer-events: none;
    opacity: .5;
}

.btn-link,
.btn-link:visited {
    color: rgba(255, 150, 0, 1);
    /* color: rgba(255, 237, 38, 1); */
    text-decoration: none;
    font-weight: 400;
}

.btn-link:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: underline;
}

.btn.disabled {
    cursor: default;
    pointer-events: none;
    opacity: .5;
}

.bg-light a:link.link-list-item,
.bg-light a:visited.link-list-item {
    display: block;
    text-decoration: none;
    color: black;
}

.bg-light a:hover.link-list-item,
.bg-light a:focus.link-list-item {
    background-color: rgba(0, 0, 0, .1);

}

.bg-dark a:link.link-list-item,
.bg-dark a:visited.link-list-item {
    display: block;
    text-decoration: none;
    color: white;
}

.bg-dark a:hover.link-list-item,
.bg-dark a:focus.link-list-item {
    background-color: rgba(255, 255, 255, .1)
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    margin-bottom: 1rem;
}

h1,
.h1 {
    font-size: 1.75em;
}

h1.heavy {
    font-size: 4.5em;
    font-weight: 900;
    color: #454545;
}

h2,
.h2 {
    font-size: 1.5em;
}

h3,
.h3 {
    font-size: 1.25em;
}

h4,
.h4 {
    font-size: 1em;
}

h5,
.h5 {
    font-size: 1rem;
}

h6,
.h6 {
    font-size: .75em;
}

.feature-banner {
    /* margin-left: -.75em; */
    overflow: hidden;
    right: 60px;
}

.lightbox-slide {
    transform: translateY(30%);
    opacity: 0;
    transition: transform .3s ease-in-out, opacity .5s ease-in-out;
}

.lightbox-slide.active {
    transform: translateY(0);
    opacity: 1;
}

.feature-banner::after {
    content: ' ';
    border-radius: 80%;
    position: absolute;
    z-index: 5;
    left: 155px;
    right: -400px;
    top: -10px;
    bottom: -60px;

}

.call-animation {
    border: 1px solid rgba(255, 150, 0, 1);
    transition: background-color .5s ease-in-out;
    animation: calling 2s infinite;
}

@keyframes calling {
    0% {
        background-color: rgb(32, 32, 32, .7);
        box-shadow: 0px 0px 20px rgba(255, 150, 0, 0);
        /* border: 1px solid rgba(255, 150, 0, .6); */
    }

    /* 25% {
        background-color: yellow;
    }*/

    50% {
        background-color: rgba(65, 64, 64, 0.7);
        box-shadow: 0px 0px 20px rgba(255, 150, 0, 1);
        /* border: 1px solid rgba(255, 150, 0, 1); */
    }

    100% {
        background-color: rgb(32, 32, 32, .7);
        box-shadow: 0px 0px 20px rgba(255, 150, 0, 0);
        /* border: 1px solid rgba(255, 150, 0, .6); */
    }
}

/* CONNECT EMOJIS */

.emoji-wave::after {
    content: "\1F44B";
}

.emoji-link::after {
    content: "\1F517";
}

.emoji-wink::after {
    content: "\1F609";
}

.emoji-unlock::after {
    content: "\1F513";
}

/* REACTION EMOJIS */

.emoji-squirt::after {
    content: "\1F4A6";
}

.emoji-eyes::after {
    content: "\1F440";
}

.emoji-fire::after {
    content: "\1F525";
}

.emoji-heart-fire::after {
    content: "❤️‍🔥";
}

.emoji-horns-smile::after {
    content: "\1F608";
}

.emoji-heart-beating::after {
    content: "\1F493";
}

.emoji-100::after {
    content: "\1F4AF";
}

.emoji-head-explode::after {
    content: "\1F92F";
}

.emoji-eggplant::after {
    content: "\1F346";
}

.emoji-firecracker::after {
    content: "\1F9E8";
}

.emoji-hands-clapping::after {
    content: "\1F44F";
}

.bg-white .feature-banner::after {
    background-color: rgb(255, 255, 255);
    /* box-shadow: -1px -4px 10px rgba(255, 255, 255, .6); */
}

.rotateVertical {
    transform: rotate(-90deg)
}

.bg-graient-top-down-dark {
    background: linear-gradient(180deg, rgba(65, 65, 65, .25)5%, rgba(255, 255, 255, 0) 100%);
}

.bg-graient-top-down-md {
    background: linear-gradient(180deg, rgba(70, 70, 70, .1)5%, rgba(255, 255, 255, 0) 100%);
}

.base-size {
    font-size: 1em !important;
}

h1.app-header {
    font-size: 60px !important;
}

.text-xs {
    font-size: .85rem;
}

.text-sm {
    font-size: 1.1rem;
}

.text-primary {
    color: rgba(255, 150, 0, 1) !important;
}

.text-yellow {
    color: rgba(255, 237, 38, 1) !important;
}

.text-light-gray {
    color: #959595 !important;
}

.text-md-dark {
    color: #656565 !important;
}

.bg-md-dark {
    background-color: #454545 !important;
    background-color: #30363d !important;
}

.bg-md2-dark {
    background-color: #292929 !important;
    background-color: #2b3036 !important;

}

.bg-light-dark {
    background-color: #555555 !important;
}

.bg-gray-light {
    background-color: #e3e8ec !important;
}

.bg-gray-md-light {
    background-color: #e1e1e1 !important;
}

.bg-gray-x-light {
    background-color: #fff8f8 !important;
}

.bg-primary {
    background-color: rgba(255, 150, 0, 1) !important;
}

.bg-white-op-05 {
    background-color: rgba(255, 255, 255, .05) !important;
}

.text-light-opaque {
    color: rgba(255, 255, 255, .7) !important;
}

.bg-primary-opaque {
    background-color: rgba(255, 150, 0, .50) !important;
}

.bg-primary-opaque-light {
    background-color: rgba(255, 150, 0, .2) !important;
}

.border-transparent {
    border-color: transparent !important;
}

.border-primary {
    border-color: rgba(255, 150, 0, 1) !important;
}

.border-md-dark {
    border-color: #434343 !important;
}

.border-md-light {
    border-color: rgba(255, 255, 255, 0) !important;
}

.border-md-light-dark {
    border-color: rgba(0, 0, 0, .25) !important;
}

.border-light-dark {
    border-color: rgba(0, 0, 0, .1) !important;
}

.border-light-gray {
    border-color: rgba(255, 255, 255, .15) !important;
}

.container-360 {
    max-width: 360px;
}

.container-400 {
    max-width: 400px;
}

.container-600 {
    max-width: 600px;

}

.container-700 {
    max-width: 700px;

}

.container-800 {
    max-width: 800px;

}

.container-900 {
    max-width: 900px;

}

.container-980 {
    max-width: 980px;

}

.container-1000 {
    max-width: 1000px;

}

.container-1200 {
    max-width: 1200px;

}

.container-1345 {
    max-width: 1345px;

}

.container-1400 {
    max-width: 1400px;
}

.container-1500 {
    max-width: 1500px;

}

.container-1700 {
    max-width: 1700px;

}

.container-1800 {
    max-width: 1800px;

}

.container-calc {
    max-width: calc(100vw - 60px);

}

.col-1_10,
.col-sm-1_10,
.col-md-1_10,
.col-lg-1_10,
.col-xl-1_10 {
    flex: 0 0 auto;
    width: 10%;
}

.carousel-indicators {
    bottom: auto !important;
    top: 0px;
    margin-top: 0rem;

}

.carousel-indicators [data-bs-target] {

    width: 20px;
}

.carousel-control-prev {
    pointer-events: none;
    width: 5%;
}

.carousel-control-prev-icon {
    pointer-events: auto;
}

.carousel-control-next {
    pointer-events: none;
    width: auto;
    padding-right: 5px;
}

.carousel-control-next-icon {
    pointer-events: auto;
}

.img-blur {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    /* filter: blur(0px);
    -webkit-filter: blur(0px); */
    transition: filter .3s ease-in-out;

}

.no-blur {
    filter: blur(0) !important;
    -webkit-filter: blur(0) !important;

}

.cal-post .cal-day {
    transition: border-bottom-color .3s ease-in-out;
}

.cal-post:hover .cal-day {
    border-bottom-color: rgba(255, 150, 0, 1) !important;
}

.group-card,
.friend-card,
.reel-post,
.media-post {
    transition: border-color .3s ease-in-out;
}

.group-card:hover,
.friend-card:hover,
.reel-post:hover,
.media-post:hover {
    border-color: rgba(255, 150, 0, 1) !important;
}

/* .collapse.show ~ .btn {
    background-color: #000 !important;
} */

.sub-link-list a~.text-label {
    border-bottom: 3px solid transparent;
}

.sub-link-list a:hover~.text-label {
    border-bottom: 3px solid rgba(255, 150, 0, 1);
}

.sub-link-list a.activeNow~.text-label {
    border-bottom: 3px solid rgba(255, 150, 0, 1);

}

nav {
    font-size: 1.1rem;
}

.nav-top-links a:link,
.nav-top-links a:visited {
    text-decoration: none;
    color: rgb(248, 249, 250);
    font-weight: 400 !important;
    transition: background-color .3s ease-in-out;
}

.nav-top-links a:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, .175) !important;
    color: white !important;
}

.nav-top-links a:focus {
    box-shadow: 0 !important;
}

.nav-top-links a:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, .175) !important;
}

.nav-top-links a:link.active,
.nav-top-links a:visited.active {
    background-color: rgba(255, 255, 255, .125);
    /* pointer-Events: none; */
}

.nav-offset {
    margin-top: 58px;
}

.nav-offset.isMobileOnly {
    margin-top: 52px;
}

.app-feature {
    font-size: .85rem !important;
}

.search-menu,
.user-menu {
    transform: translateY(-110%);
    transition: transform .3s ease-in-out;
}

.search-menu.show-menu,
.user-menu.show-menu {
    transform: translateY(0);
}

.chat-list,
.app-menu {
    transform: translateX(-110%);
    transition: transform .3s ease-in-out;
}

.chat-list.show-menu,
.app-menu.show-menu {
    transition: transform .3s ease-in-out;
    transform: translateX(0);
}

.shortcut-menu {
    transform: translateX(-110%);
    transition: transform .3s ease-in-out;
}

.shortcut-menu.show-menu {
    transform: translateX(0);
}

.notifications-menu {
    transform: translateX(110%);
    transition: transform .3s ease-in-out;
}

.notifications-menu.show-menu {
    transition: transform .3s ease-in-out;
    transform: translateX(0);
}

.ribbon-menu {
    transform: translateY(-110%);
    transition: transform .5s ease-in-out;
}

.ribbon-menu.show-menu {
    transform: translateY(0);
}

.cart-window {
    transform: translateX(110%);
    transition: transform .3s ease-in-out;
}

.cart-window.show-menu {
    transform: translateX(0);
}

.form-check-input {
    cursor: pointer;
    border-color: rgba(255, 255, 255);
}

.form-check-input:checked {
    background-color: rgba(255, 150, 0, 1);
    border-color: rgba(255, 150, 0, 1);
}

.app-menu a:link,
.app-menu a:visited {
    color: rgb(248, 249, 250);
    font-weight: normal;
    text-decoration: none;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.app-menu a:hover,
.app-menu a:focus,
.app-menu a:active {
    color: white;
    font-weight: normal;
    text-decoration: none;
    background-color: rgba(255, 255, 255, .05);
}

.app-menu a:hover ion-icon,
.app-menu a:focus ion-icon,
.app-menu a:active ion-icon {
    transition: background-color .3s ease-in-out;
    color: rgba(255, 150, 0, 1);
}

.app-menu a:link.active,
.app-menu a:visited.active {
    background-color: rgba(0, 0, 0, .20) !important;
    /* pointer-Events: none; */
}

.search-menu a:link,
.search-menu a:visited {
    color: rgb(20, 20, 20);
    font-weight: normal;
    text-decoration: none;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.search-menu a:hover,
.search-menu a:focus,
.search-menu a:active {
    font-weight: normal;
    text-decoration: none;
    background-color: rgba(0, 0, 0, .1);
}

.search-menu a:hover ion-icon,
.search-menu a:focus ion-icon,
.search-menu a:active ion-icon {
    transition: background-color .3s ease-in-out;
    color: rgba(255, 150, 0, 1);
}

.search-menu a:link.active,
.search-menu a:visited.active {
    background-color: rgba(0, 0, 0, .20) !important;
    pointer-Events: none;
}

.connect-window a:link,
.connect-window a:visited {
    color: rgb(248, 249, 250);
    font-weight: normal;
    text-decoration: none;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.connect-window a.text-dark:hover,
.connect-window a.text-dark:focus,
.connect-window a.text-dark:active {
    color: white;
    font-weight: normal;
    text-decoration: none;
    background-color: rgba(0, 0, 0, .1);
}

.connect-window a.text-light:hover,
.connect-window a.text-light:focus,
.connect-window a.text-light:active {
    color: white;
    font-weight: normal;
    text-decoration: none;
    background-color: rgba(255, 255, 255, .1);
}

.connect-window a:link.active.text-dark,
.connect-window a:visited.active.text-dark {
    background-color: rgba(0, 0, 0, .20) !important;
    pointer-Events: none;
}

.connect-window a:link.active.text-light,
.connect-window a:visited.active.text-light {
    background-color: rgba(255, 255, 255, .10) !important;
    pointer-Events: none;

}

.purchase-list a:link,
.purchase-list a:visited {
    color: #212529;
    text-decoration: none;
    transition: background-color .3s ease-in-out;
}

.purchase-list a:hover,
.purchase-list a:focus,
.purchase-list a:active {
    color: #212529;
    font-weight: normal;
    text-decoration: none;
    background-color: rgba(0, 0, 0, .1) !important;
}

button.btn-menu-std {
    display: block;
    border: none;
    border-radius: 0;
}

button.btn-menu-std:hover {
    background-color: rgba(255, 255, 255, .05);
}

button.btn-menu-std:hover ion-icon {
    color: rgba(255, 150, 0, 1);
}

button.btn-menu-std.active {
    pointer-events: none;
    background-color: #404040 !important;
}

a.btn-icon-light,
.btn-icon-light {
    background-color: transparent;
    transition: background-color .25s ease-in-out;
    color: #f8f9fa !important;
    border-color: transparent;
}

a.btn-icon-light:hover,
a.btn-icon-light.focus,
.btn-icon-light:hover,
.btn-icon-light.focus {
    color: #f8f9fa !important;
    background-color: rgba(255, 255, 255, .2) !important;
    border-color: transparent;
}

a.btn-icon-light:focus,
.btn-icon-light:focus {
    box-shadow: 0 !important;
    border-color: transparent;
}

a.btn-icon-light:not(:disabled):not(.disabled):active,
.btn-icon-light:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, .15) !important;
    border-color: transparent;
}

.btn-icon-light.active {
    background-color: rgba(255, 255, 255, .15);
    border-color: transparent;
}

a.btn-icon-md,
.btn-icon-md {
    background-color: rgba(0, 0, 0, .05) !important;
    transition: background-color .25s ease-in-out !important;
    color: #808080 !important;
    border-color: transparent;

}

a.btn-icon-md:hover,
a.btn-icon-md.focus,
.btn-icon-md:hover,
.btn-icon-md.focus {
    color: #404040 !important;
    background-color: rgba(0, 0, 0, .05) !important;
    border-color: transparent;
    border-color: transparent;
}

a.btn-icon-md:focus,
.btn-icon-md:focus {
    box-shadow: 0 !important;
    border-color: transparent;
}

a.btn-icon-md:not(:disabled):not(.disabled):active,
.btn-icon-md:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, .1) !important;
    border-color: transparent;
}

.btn-icon-md.active {
    background-color: rgba(0, 0, 0, .1) !important;
    border-color: transparent;
    border-color: transparent;
}

/**/

a.btn-icon-dark,
.btn-icon-dark {
    background-color: transparent !important;
    transition: background-color .25s ease-in-out !important;
    color: #202020 !important;
    border-color: transparent;
}

a.btn-icon-dark:hover,
a.btn-icon-dark.focus,
.btn-icon-dark:hover,
.btn-icon-dark.focus {
    color: #202020 !important;
    background-color: rgba(0, 0, 0, .2) !important;
    border-color: transparent;
}

a.btn-icon-dark:focus,
.btn-icon-dark:focus {
    box-shadow: 0 !important;
}

a.btn-icon-dark:not(:disabled):not(.disabled):active,
.btn-icon-dark:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, .15) !important;
}

.btn-icon-dark.active {
    background-color: rgba(0, 0, 0, .1) !important;

}

a.btn-rectagle-icon-light,
.btn-rectagle-icon-light {
    background-color: transparent;
    transition: background-color .25s ease-in-out;
    color: #f8f9fa !important;

}

a.btn-rectagle-icon-light:hover,
a.btn-rectagle-icon-light.focus,
.btn-rectagle-icon-light:hover,
.btn-rectagle-icon-light.focus {
    color: #f8f9fa !important;
    background-color: rgba(255, 255, 255, .2) !important;
}

a.btn-rectagle-icon-light:focus,
.btn-rectagle-icon-light:focus {
    box-shadow: 0 !important;
}

a.btn-rectagle-icon-light:not(:disabled):not(.disabled):active,
.btn-rectagle-icon-light:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, .15) !important;
}

.btn {
    font-weight: 400 !important;
}

.btn-primary {

    background-color: rgba(255, 150, 0, 1) !important;
    border-color: transparent;
    text-decoration: none !important;
}

.bg-light .btn-primary,
.bg-light a.btn-primary {
    color: #fff;
    font-weight: 500 !important;
}

.bg-light .btn-primary:hover,
.bg-light a.btn-primary:hover,
.bg-light .btn-primary:focus,
.bg-light a.btn-primary:focus {
    color: #000 !important;
    background-color: rgba(255, 150, 15, .8) !important;
    border-color: transparent;

}

.bg-dark .btn-primary,
.bg-dark a.btn-primary {
    color: #000;
    font-weight: 400 !important;
}

.bg-dark .btn-primary:hover,
.bg-dark .btn-primary:focus,
.bg-dark a.btn-primary:hover,
.bg-dark a.btn-primary:focus {
    color: #fff !important;
    background-color: rgba(255, 150, 15, .8) !important;
    border-color: transparent;
}

.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.25rem rgba(255, 150, 15, .5) !important;
}

.text-light .btn-secondary {
    /* color: #000 !important; */
    color: #fff !important;
    font-weight: 500 !important;
}

.text-dark .btn-secondary {
    color: #fff !important;
    font-weight: 400 !important;
}

.btn-secondary:hover {
    color: #fff !important;
}


.btn-group-xs>.btn,
.btn-xs {
    --bs-btn-padding-y: 0.175rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.825rem;
    --bs-btn-border-radius: 0.25rem;
}

.nav-bottom-links a:link,
.nav-bottom-links a:visited {
    text-decoration: none;
    color: rgb(248, 249, 250);
    font-size: .75em;
    font-weight: 300 !important;
    transition: background-color .3s ease-in-out;
}

.nav-bottom-links a:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, .175) !important;
    color: white !important;
}

.nav-bottom-links a:focus {
    box-shadow: 0 !important;
}

.nav-bottom-links a:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, .175) !important;
}

.nav-bottom-links a:link.active,
.nav-bottom-links a:visited.active {
    background-color: rgba(255, 255, 255, .125);
    pointer-Events: none;
}

@media (min-width: 992px) {
    .col-lg-20perc {
        flex: 0 0 auto;
        width: 20%;
    }

    /* .col-lg-20perc {
            flex: 0 0 auto;
            width: 18.5%;
        } */

    .col-lg-14_28perc {
        flex: 0 0 auto;
        width: 14.2857%;
    }

    .col-lg-13_33perc {
        flex: 0 0 auto;
        width: 13.33%;
    }

    .col-lg-12_5perc {
        flex: 0 0 auto;
        width: 12.5%;
    }

    /* .col-lg-12_5perc {
            flex: 0 0 auto;
            width: 13.33%;
        } */

    .col-lg-10perc {
        flex: 0 0 auto;
        width: 10%;
    }

}

@media (max-width: 576px) {

    h1,
    .h1 {
        font-size: 1.60em !important;
    }

    h2,
    .h2 {
        font-size: 1.35em !important;
    }

    h3,
    .h3 {
        font-size: 1.10em !important;
    }

    h1.app-header {
        font-size: 30px !important;
    }

    body {

        font-size: 1.10rem;

    }

}